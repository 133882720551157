import React from "react";
import { Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  ReferenceLine,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { profileVarsUnits } from "../config/Constants";

function orderData(variable, cluster_0, cluster_1, cluster_2, cluster_3) {
  return { variable, cluster_0, cluster_1, cluster_2, cluster_3 };
}

function ProfilePlot({ data, tipoVar }) {
  const theme = useTheme();
  const orderedData = Object.entries(data).map(([variable, clusters]) => {
    return orderData(
      variable,
      clusters.cluster_0.normalized,
      clusters.cluster_1.normalized,
      clusters.cluster_2.normalized,
      clusters.cluster_3.normalized
    );
  });

  const clusterWeights = Object.values(data)[0];

  const clusterWeightsFiltered = Object.keys(clusterWeights)
    .filter((key) => key.startsWith("cluster_"))
    .reduce((acc, key) => {
      acc[key] = clusterWeights[key].weight * 100;
      return acc;
    }, {});

  return (
    <Fragment>
      <ResponsiveContainer>
        <LineChart
          data={orderedData}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="variable"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            interval={0}
            angle={-30}
            dx={-58}
            dy={30}
            padding={{ left: 30, right: 30 }}
            height={100}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: "middle",
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Valor
            </Label>
          </YAxis>
          <Tooltip
            formatter={(value, name, props) => {
              let valueRaw = data[props.payload.variable][name].value;
              let valueNorm = value;
              if (tipoVar === "ABSOLUTAS") {
                return `${valueNorm.toFixed(4)} (${valueRaw.toFixed(4)} ${
                  profileVarsUnits[props.payload.variable]
                })`;
              }
              valueRaw = valueRaw * 100;
              return `${valueNorm.toFixed(2)} (${valueRaw.toFixed(2)}% reales)`;
            }}
          />
          <Legend
            verticalAlign="top"
            align="right"
            formatter={(value) =>
              `${value} (${clusterWeightsFiltered[value].toFixed(0)}%)`
            }
          />
          <ReferenceLine
            y={1}
            label={{
              position: "insideRight",
              dy: -10,
              value: "Max",
              fill: "red",
              fontSize: 14,
            }}
            stroke="red"
            strokeDasharray="3 3"
          />
          <Line
            dataKey="cluster_0"
            stroke={theme.clusters.cluster_0}
            strokeWidth={1.5}
          />
          <Line
            dataKey="cluster_1"
            stroke={theme.clusters.cluster_1}
            strokeWidth={1.5}
          />
          <Line
            dataKey="cluster_2"
            stroke={theme.clusters.cluster_2}
            strokeWidth={1.5}
          />
          <Line
            dataKey="cluster_3"
            stroke={theme.clusters.cluster_3}
            strokeWidth={1.5}
          />
        </LineChart>
      </ResponsiveContainer>
    </Fragment>
  );
}

export default ProfilePlot;
