import React from "react";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FeatureImportance from "./FeatureImportance";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import SpeedIcon from "@mui/icons-material/Speed";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { orderPerformanceData } from "../utils/Utils";
import moment from "moment";

const SummaryCard = ({ data }) => {
  const _renderUnits = (variable) => {
    if (data.variableUnits[variable])
      return ` [${data.variableUnits[variable]}]`;
    return "";
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={6} lg={6}>
        <List sx={{ width: "100%" }}>
          <ListItem>
            <ListItemIcon>
              <ModelTrainingIcon />
            </ListItemIcon>
            <ListItemText
              id="model-name"
              primary="Nombre"
              secondary={
                data.targetVariable + _renderUnits(data.targetVariable)
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SpeedIcon />
            </ListItemIcon>
            <ListItemText
              id="model-score-r2"
              primary={
                <Typography variant="body1">
                  R<sup>2</sup>
                </Typography>
              }
              secondary={data.score.R2.toFixed(4)}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SpeedIcon />
            </ListItemIcon>
            <ListItemText
              id="model-score-mae"
              primary={<Typography variant="body1">MAE</Typography>}
              secondary={data.score.MAE.toFixed(4)}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>
            <ListItemText
              id="model-period"
              primary="Periodo"
              secondary={data.period}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText
              id="model-algorithm"
              primary="Algoritmo"
              secondary={data.algorithm}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText
              id="model-last-modified"
              primary="Última modificación"
              secondary={moment(data.lastModified).format(
                "DD/MM/YYYY HH:MM:ss"
              )}
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Box sx={{ width: "100%", height: 550 }}>
          <FeatureImportance
            data={orderPerformanceData(data.variableImportance)}
            title="Importancia de variables"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SummaryCard;
