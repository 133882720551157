import React from "react";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import moment from "moment";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import FeatureSlider from "../components/FeatureSlider";
import MediationIcon from "@mui/icons-material/Mediation";
import { backendURL, periodRanges } from "../config/Constants";
import { fetchData } from "../utils/Utils";
import { Divider, List, ListItemAvatar, Stack } from "@mui/material";

const Simulation = ({
  period,
  periodCustomEnd,
  createdModels,
  selectedModel,
  selectedModelFeatures,
  setSelectedModelFeatures,
  selectedModelUnit,
}) => {
  const [cookies] = useCookies(["token"]);
  const [selectedModelPrediction, setSelectedModelPrediction] = useState(null);

  const setter = (variable, newValue) => {
    setSelectedModelFeatures(
      selectedModelFeatures.map((item) => {
        if (item.variable === variable) {
          return { ...item, value: newValue };
        }
        return item;
      })
    );
  };

  const fetchSimulate = async (modelID, features, fecha) => {
    const data = features.reduce((acc, { variable, value }) => {
      acc[variable] = value;
      return acc;
    }, {});

    const init = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + cookies.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Fecha: fecha,
        ...data,
      }),
    };

    const setPred = (data) => {
      setSelectedModelPrediction(data);
    };

    await fetchData(
      `${backendURL}/ai/modelo/${modelID}/predict`,
      "",
      init,
      setPred
    );
  };

  useEffect(() => {
    setSelectedModelPrediction(null);
  }, [selectedModel]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            marginTop={1}
            padding={1}
            columnSpacing={5}
          >
            {selectedModelFeatures?.map((feature, index) => (
              <Grid item xs={12} md={6} key={feature.variable}>
                <FeatureSlider
                  feature={feature}
                  setter={setter}
                  index={index + 1}
                />
                <Divider />
              </Grid>
            ))}
          </Grid>
          {selectedModelFeatures?.length > 0 && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              marginTop={1}
            >
              <Button
                variant="contained"
                onClick={() => {
                  fetchSimulate(
                    createdModels.find((model) => model.name === selectedModel)
                      .id,
                    selectedModelFeatures,
                    period === 3
                      ? moment(periodCustomEnd).format("DD/MM/YYYY")
                      : moment(periodRanges[period][1]).format("DD/MM/YYYY")
                  );
                }}
              >
                Simular
              </Button>
              {selectedModelPrediction && (
                <List sx={{ maxHeight: "md" }}>
                  <ListItem>
                    <ListItemAvatar>
                      <MediationIcon />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Predicción"
                      secondary={
                        parseFloat(selectedModelPrediction.pred.toFixed(4)) +
                        (selectedModelUnit ? ` [${selectedModelUnit}]` : "")
                      }
                    />
                  </ListItem>
                </List>
              )}
            </Stack>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Simulation;
