import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
  clusters: {
    cluster_0: "#396ab1",
    cluster_1: "#da7c30",
    cluster_2: "#3e9651",
    cluster_3: "#cc2529",
  },
  prediction: {
    actual: "#396ab1",
    predicted: "#cc2529",
  }
});

export default theme;
