import React from "react";
import { Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import { profileVarsUnits } from "../config/Constants";

function orderData(variable, cluster_0, cluster_1, cluster_2, cluster_3) {
  return { variable, cluster_0, cluster_1, cluster_2, cluster_3, reference: 1 };
}

function SpiderPlot({ data, tipoVar }) {
  const theme = useTheme();
  const orderedData = Object.entries(data).map(([variable, clusters]) => {
    return orderData(
      variable,
      clusters.cluster_0.normalized,
      clusters.cluster_1.normalized,
      clusters.cluster_2.normalized,
      clusters.cluster_3.normalized
    );
  });

  const clusterWeights = Object.values(data)[0];

  const clusterWeightsFiltered = Object.keys(clusterWeights)
    .filter((key) => key.startsWith("cluster_"))
    .reduce((acc, key) => {
      acc[key] = clusterWeights[key].weight * 100;
      return acc;
    }, {});

  return (
    <Fragment>
      <ResponsiveContainer>
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={orderedData}>
          <PolarGrid strokeDasharray="3 3" />
          <PolarAngleAxis dataKey="variable" />
          <PolarRadiusAxis angle="90" />
          <Tooltip
            formatter={(value, name, props) => {
              let valueNorm = value;
              let valueRaw = data[props.payload.variable][name].value;
              if (tipoVar === "ABSOLUTAS") {
                return `${valueNorm.toFixed(4)} (${valueRaw.toFixed(4)} ${
                  profileVarsUnits[props.payload.variable]
                })`;
              }
              valueRaw = valueRaw * 100;
              return `${valueNorm.toFixed(2)} (${valueRaw.toFixed(2)}% reales)`;
            }}
          />
          <Legend
            verticalAlign="top"
            align="right"
            formatter={(value) =>
              `${value} (${clusterWeightsFiltered[value].toFixed(0)}%)`
            }
          />
          <Radar
            dataKey="reference"
            stroke="red"
            strokeDasharray="3 3"
            strokeWidth={2}
            fill="transparent"
            legendType="none"
            tooltipType="none"
          />
          <Radar
            name="cluster_0"
            dataKey="cluster_0"
            stroke={theme.clusters.cluster_0}
            strokeWidth={1.5}
            fill={theme.clusters.cluster_0}
            fillOpacity={0.05}
          />
          <Radar
            name="cluster_1"
            dataKey="cluster_1"
            stroke={theme.clusters.cluster_1}
            strokeWidth={1.5}
            fill={theme.clusters.cluster_1}
            fillOpacity={0.05}
          />
          <Radar
            name="cluster_2"
            dataKey="cluster_2"
            stroke={theme.clusters.cluster_2}
            strokeWidth={1.5}
            fill={theme.clusters.cluster_2}
            fillOpacity={0.05}
          />
          <Radar
            name="cluster_3"
            dataKey="cluster_3"
            stroke={theme.clusters.cluster_3}
            strokeWidth={1.5}
            fill={theme.clusters.cluster_3}
            fillOpacity={0.05}
          />
        </RadarChart>
      </ResponsiveContainer>
    </Fragment>
  );
}

export default SpiderPlot;
