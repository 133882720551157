import React from "react";
import { Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  ReferenceLine,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import Title from "./Title";
import moment from "moment";

function ForecastPlot({ data }) {
  const dateFormater = (date) => {
    return moment(date).format("MMM YYYY");
  };

  const theme = useTheme();

  return (
    <Fragment>
      <Title>Predicción de calidad del agua</Title>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Fecha"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            // interval={0}
            angle={-30}
            tickFormatter={dateFormater}
            dx={-30}
            dy={15}
            padding={{ left: 30, right: 30 }}
            height={65}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            domain={[0, 45]}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: "middle",
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Días
            </Label>
          </YAxis>
          <Tooltip labelFormatter={(t) => dateFormater(t)} />
          <Legend verticalAlign="top" align="right" />
          <ReferenceLine
            x={data.at(-5).Fecha}
            label={{
              position: "insideTopLeft",
              // dy: -10,
              value: "→Predicción",
              fill: "grey",
              fontSize: 14,
            }}
            stroke="black"
            strokeDasharray="3 3"
          />
          <Line
            dataKey="cluster_0"
            stroke={theme.clusters.cluster_0}
            dot={false}
            strokeWidth={1.5}
          />
          <Line
            dataKey="cluster_1"
            stroke={theme.clusters.cluster_1}
            dot={false}
            strokeWidth={1.5}
          />
          <Line
            dataKey="cluster_2"
            stroke={theme.clusters.cluster_2}
            dot={false}
            strokeWidth={1.5}
          />
          <Line
            dataKey="cluster_3"
            stroke={theme.clusters.cluster_3}
            dot={false}
            strokeWidth={1.5}
          />
        </LineChart>
      </ResponsiveContainer>
    </Fragment>
  );
}

export default ForecastPlot;
