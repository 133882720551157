import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
  CartesianGrid,
  Brush,
} from "recharts";
import moment from "moment";

function PredictionPlot({ data, title }) {
  const dateFormater = (date) => {
    return moment(date).format("MMM YYYY");
  };

  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: 550,
        marginBottom: 2,
      }}
    >
      <Typography
        variant="h6"
        sx={{ textAlign: "center" }}
        color={theme.palette.text.secondary}
      >
        {title}
      </Typography>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 20,
            left: 16,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Fecha"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            angle={-30}
            tickFormatter={dateFormater}
            dx={-30}
            dy={15}
            padding={{ left: 30, right: 30 }}
            height={65}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            domain={["auto", "auto"]}
            tickFormatter={(value) => value.toFixed(0)}
          />
          <Tooltip
            labelFormatter={(t) => moment(t).format("DD/MM/YYYY")}
            formatter={(value, name, props) => `${value.toFixed(4)}`}
          />
          <Legend verticalAlign="top" align="right" />
          <Line
            dataKey="actual"
            stroke={theme.prediction.actual}
            dot={false}
            strokeWidth={1.5}
          />
          <Line
            dataKey="pred"
            stroke={theme.prediction.predicted}
            dot={false}
            strokeWidth={1.5}
          />
          <Brush
            dataKey="Fecha"
            tickFormatter={(t) => moment(t).format("DD/MM/YYYY")}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default PredictionPlot;
