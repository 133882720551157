import React from "react";
import { useState, useEffect, useCallback } from "react";
import { Routes, Route } from "react-router-dom";
import { useCookies } from "react-cookie";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import ProfilePage from "./ProfilePage";
import ModelPage from "./ModelPage";
import SimulOptimPage from "./SimulOptimPage";
import { mainListItems, secondaryListItems } from "../components/listItems";
import DialogCalendar from "../components/DialogCalendar";
import { backendURL, periodMap, periodRanges } from "../config/Constants";
import { fetchData, notify } from "../utils/Utils";
import moment from "moment";
import NotFound from "../components/NotFound";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function Dashboard({ signout }) {
  const [cookies] = useCookies(["user"]);
  const [open, setOpen] = useState(true);
  const [dialogCalendarOpen, setDialogCalendarOpen] = useState(false);
  const [periodCustomStart, setPeriodCustomStart] = useState(
    moment(periodRanges[2][0])
  );
  const [periodCustomEnd, setPeriodCustomEnd] = useState(
    moment(periodRanges[2][1])
  );
  const [selectedDates, setSelectedDates] = useState([null, null]);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [periodo, setPeriodo] = useState("Período 2");
  const [lastDBDate, setLastDBDate] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeStart = (date) => {
    setPeriodCustomStart(date || "");
  };

  const handleChangeEnd = (date) => {
    setPeriodCustomEnd(date || "");
  };

  const handleClickOpen = () => {
    setDialogCalendarOpen(true);
  };

  const handleClose = (isOkPressed) => {
    setDialogCalendarOpen(false);
    if (isOkPressed && periodCustomStart && periodCustomEnd) {
      setSelectedDates([periodCustomStart, periodCustomEnd]);
      setPeriodo("Personalizado");
    }
  };

  const handleClickRecreateDB = async () => {
    notify(
      enqueueSnackbar,
      "Recreando base de datos. Esto puede tardar unos minutos...",
      "info"
    );

    const init = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + cookies.token,
        Accept: "application/json",
      },
    };

    const setDBDate = (data) => {
      notify(
        enqueueSnackbar,
        `Base de datos recreada. Última fecha: ${data.last_date}`,
        "success"
      );
      setLastDBDate(data.last_date);
      if (periodo === "Período 2")
        setPeriodCustomEnd(moment(data.last_date, "DD/MM/YYYY"));
    };

    await fetchData(`${backendURL}/parser/`, "", init, setDBDate);
  };

  const fetchLastDBDate = useCallback(async () => {
    const init = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookies.token,
        Accept: "application/json",
      },
    };

    const setDBDate = (data) => {
      setLastDBDate(data?.last_date);
    };

    await fetchData(`${backendURL}/parser/lastDate`, "", init, setDBDate);
  }, [cookies]);

  useEffect(() => {
    fetchLastDBDate();
  }, [fetchLastDBDate]);

  const _getPeriodString = () => {
    if (periodo === "Período 1")
      return (
        moment(periodRanges[periodMap[periodo]][0]).format("D/M/YYYY") +
        " - " +
        moment(periodRanges[periodMap[periodo]][1]).format("D/M/YYYY")
      );
    if (periodo === "Período 2")
      return (
        moment(periodRanges[periodMap[periodo]][0]).format("D/M/YYYY") +
        " - " +
        (lastDBDate
          ? lastDBDate
          : moment(periodRanges[periodMap[periodo]][1]).format("D/M/YYYY"))
      );
    return (
      moment(selectedDates[0]).format("D/M/YYYY") +
      " - " +
      moment(selectedDates[1]).format("D/M/YYYY")
    );
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <DialogCalendar
        handleOpen={dialogCalendarOpen}
        handleClose={handleClose}
        handleChangeStart={handleChangeStart}
        handleChangeEnd={handleChangeEnd}
        periodCustomStart={periodCustomStart}
        periodCustomEnd={periodCustomEnd}
        minDate={periodCustomStart}
        maxDate={periodCustomEnd}
      />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  EDAR Dashboard
                </Typography>
              </Grid>
              <Grid item>
                <Stack direction="row" alignItems="center">
                  <Typography
                    variant="button"
                    color="inherit"
                    sx={{ flexGrow: 1 }}
                    noWrap
                  >
                    {_getPeriodString()}
                  </Typography>
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => handleClickOpen()}
                  >
                    <CalendarIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
            <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
              <Select
                id="periodo-select"
                value={periodo}
                onChange={(e) => {
                  if (e.target.value !== "Personalizado") {
                    setPeriodo(e.target.value);
                    setPeriodCustomStart(
                      moment(periodRanges[periodMap[e.target.value]][0])
                    );

                    if (e.target.value === "Período 2")
                      setPeriodCustomEnd(
                        lastDBDate
                          ? moment(lastDBDate, "DD-MM-YYYY")
                          : moment(periodRanges[periodMap[e.target.value]][1])
                      );
                    else
                      setPeriodCustomEnd(
                        moment(periodRanges[periodMap[e.target.value]][1])
                      );
                  } else {
                    handleClickOpen();
                  }
                }}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value={"Período 1"}>Período 1</MenuItem>
                <MenuItem value={"Período 2"}>Período 2</MenuItem>
                <MenuItem value={"Personalizado"}>Personalizado</MenuItem>
              </Select>
            </FormControl>
            {/* Pending user info */}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: [1],
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={1}
            >
              <Avatar src={"./logo192.png"} alt="EDAR DT" variant="square" />
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ color: "#6B6B6B" }}
              >
                EDAR DT
              </Typography>
            </Stack>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems(lastDBDate, handleClickRecreateDB, signout)}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Routes>
            <Route
              path="/"
              element={
                <ProfilePage
                  periodo={periodMap[periodo]}
                  selectedDates={selectedDates}
                />
              }
            />
            <Route
              path="/model"
              element={
                <ModelPage
                  periodo={periodMap[periodo]}
                  selectedDates={selectedDates}
                />
              }
            />
            <Route
              path="/simulate"
              element={
                <SimulOptimPage
                  periodo={periodMap[periodo]}
                  selectedDates={selectedDates}
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Dashboard;
