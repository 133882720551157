import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import WavesIcon from "@mui/icons-material/Waves";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import HistoryIcon from "@mui/icons-material/History";
import CachedIcon from "@mui/icons-material/Cached";
import LogoutIcon from '@mui/icons-material/Logout';

export const mainListItems = (
  <Fragment>
    <ListItemButton component={Link} to="/">
      <ListItemIcon>
        <WavesIcon />
      </ListItemIcon>
      <ListItemText primary="Perfil" />
    </ListItemButton>
    <ListItemButton component={Link} to="/model">
      <ListItemIcon>
        <ModelTrainingIcon />
      </ListItemIcon>
      <ListItemText primary="Modelado" />
    </ListItemButton>
    <ListItemButton component={Link} to="/simulate">
      <ListItemIcon>
        <QueryStatsIcon />
      </ListItemIcon>
      <ListItemText
        primary="Simulación y"
        secondary="Optimización"
        secondaryTypographyProps={{ variant: "body1", color: "black" }}
      />
    </ListItemButton>
  </Fragment>
);

export const secondaryListItems = (lastDBDate, handleClick, signout) => (
  <Fragment>
    <ListSubheader component="div" inset>
      Información DB
    </ListSubheader>
    <ListItem>
      <ListItemIcon>
        <HistoryIcon />
      </ListItemIcon>
      <ListItemText
        primary="Última fecha"
        secondary={lastDBDate || "No hay datos"}
      />
    </ListItem>
    <ListItemButton>
      <ListItemIcon>
        <CachedIcon />
      </ListItemIcon>
      <ListItemText primary="Recrear DB" onClick={() => handleClick()} />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Cerrar sesión" onClick={() => signout()} />
    </ListItemButton>
  </Fragment>
);
