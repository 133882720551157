import React from "react";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { profileVarsUnits } from "../config/Constants";
import { TableContainer } from "@mui/material";

function orderData(
  variable,
  cluster_0,
  cluster_1,
  cluster_2,
  cluster_3,
  tipoVar = null
) {
  return {
    variable,
    cluster_0,
    cluster_1,
    cluster_2,
    cluster_3,
    units: tipoVar || profileVarsUnits[variable],
  };
}

function ProfileTable({ data, tipoVar }) {
  const theme = useTheme();
  const orderedData = Object.entries(data).map(([variable, clusters]) => {
    return orderData(
      variable,
      clusters.cluster_0.value,
      clusters.cluster_1.value,
      clusters.cluster_2.value,
      clusters.cluster_3.value,
      tipoVar === "RENDIMIENTOS" ? "%" : null
    );
  });

  const clusters = Object.keys(orderedData[0]).filter((key) =>
    key.startsWith("cluster_")
  );

  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Cluster</TableCell>
            <TableCell>Variable</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Unidad</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderedData.map((row, index) =>
            clusters.map((cluster, clusterIndex) => (
              <TableRow key={`${index}-${clusterIndex}`}>
                <TableCell style={{ color: theme.clusters[cluster] }}>
                  {cluster}
                </TableCell>
                <TableCell>{row.variable}</TableCell>
                <TableCell>
                  {tipoVar === "ABSOLUTAS"
                    ? row[cluster].toFixed(4)
                    : (row[cluster] * 100).toFixed(2)}
                </TableCell>
                <TableCell>{row.units}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProfileTable;
