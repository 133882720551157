import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import moment from "moment";
import LoadingComponent from "../components/LoadingComponent";
import Copyright from "../components/Copyright";
import Title from "../components/Title";
import ProfilePlot from "../components/ProfilePlot";
import SpiderPlot from "../components/SpiderPlot";
import ProfileTable from "../components/ProfileTable";
import ForecastPlot from "../components/ForecastPlot";
import FeatureImportance from "../components/FeatureImportance";
import { backendURL } from "../config/Constants";
import { fetchData, orderProfileData } from "../utils/Utils";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const ProfilePage = ({ periodo, selectedDates }) => {
  const [cookies] = useCookies(["token"]);
  const [perfilData, setPerfilData] = useState(null);
  const [forecastData, setForecastData] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [tipoVar, setTipoVar] = useState("ABSOLUTAS");

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const formatForecastData = (data) => {
    data.forEach((d) => {
      d.Fecha = moment(d.Fecha).valueOf(); // date -> epoch
    });
    setForecastData(data);
  };

  const fetchPerfilData = useCallback(
    async (
      periodo,
      tipo_variable,
      normalizacion,
      periodCustomStart,
      periodCustomEnd
    ) => {
      const init = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + cookies.token,
          Accept: "application/json",
        },
      };
      const queryParams = new URLSearchParams({
        periodo,
        tipo_variable,
        normalizacion,
      });

      if (periodo === 3) {
        queryParams.append(
          "periodo_custom_start",
          periodCustomStart.format("DD/MM/YYYY")
        );
        queryParams.append(
          "periodo_custom_end",
          periodCustomEnd.format("DD/MM/YYYY")
        );
      }

      await fetchData(
        `${backendURL}/ai/perfil?`,
        queryParams,
        init,
        setPerfilData
        // setPerfilError
      );
      await fetchData(
        `${backendURL}/ai/forecast?`,
        queryParams,
        init,
        formatForecastData
        // setForecastError
      );
    },
    [cookies]
  );

  useEffect(() => {
    fetchPerfilData(periodo, tipoVar, 1, selectedDates[0], selectedDates[1]);
  }, [fetchPerfilData, periodo, tipoVar, selectedDates]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Controls */}
        <Grid item xs={12}>
          <FormControl>
            {/* Tipo de Variable */}
            <InputLabel id="tipovar-select-label">Tipo de variables</InputLabel>
            <Select
              labelId="tipovar-select-label"
              id="tipovar-select"
              value={tipoVar}
              label="Tipo de variables"
              onChange={(e) => {
                setTipoVar(e.target.value);
                fetchPerfilData(
                  periodo,
                  e.target.value,
                  1,
                  selectedDates[0],
                  selectedDates[1]
                );
              }}
            >
              <MenuItem value={"ABSOLUTAS"}>Absolutas</MenuItem>
              <MenuItem value={"RENDIMIENTOS"}>Rendimientos</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* Profile Plot */}
        <Grid item xs={12} md={6} lg={6}>
          {!perfilData ? (
            <LoadingComponent />
          ) : (
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 500,
              }}
            >
              <Title>Perfil de Calidad del Agua</Title>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Linea" />
                  <Tab label="Araña" />
                  <Tab label="Tabla" />
                </Tabs>
              </Box>
              {tabIndex === 0 && (
                <ProfilePlot data={perfilData} tipoVar={tipoVar} />
              )}
              {tabIndex === 1 && (
                <SpiderPlot data={perfilData} tipoVar={tipoVar} />
              )}
              {tabIndex === 2 && (
                <ProfileTable data={perfilData} tipoVar={tipoVar} />
              )}
            </Paper>
          )}
        </Grid>
        {/* Feature Importance */}
        <Grid item xs={12} md={6} lg={6}>
          {!perfilData ? (
            <LoadingComponent />
          ) : (
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 500,
              }}
            >
              <FeatureImportance data={orderProfileData(perfilData)} title="Peso de los indicadores" />
            </Paper>
          )}
        </Grid>
        {/* Forecast Plot */}
        <Grid item xs={12}>
          {!forecastData ? (
            <LoadingComponent />
          ) : (
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 500,
              }}
            >
              <ForecastPlot data={forecastData} />
            </Paper>
          )}
        </Grid>
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default ProfilePage;
