import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import { encode } from "base-64";
import jwt_decode from "jwt-decode";
import Dashboard from "./views/Dashboard";
import Login from "./views/Login";
import Protected from "./components/Protected";
import { backendURL } from "./config/Constants";
import { fetchData, notify } from "./utils/Utils";

export default function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [isSignedIn, setIsSignedIn] = useState(cookies.token ? true : false);
  const { enqueueSnackbar } = useSnackbar();

  const signin = async (user, password) => {
    const init = {
      method: "GET",
      headers: {
        Authorization: "Basic " + encode(user + ":" + password),
        Accept: "application/json",
      },
    };

    const setAccountData = (data) => {
      const token = jwt_decode(data.token);
      setCookie("token", data.token, {
        path: "/",
        expires: new Date(token.exp * 1000),
      });
      setIsSignedIn(true);
    };

    const notifyError = (error) => {
      notify(
        enqueueSnackbar,
        "Error al iniciar sesión. Comprueba tus credenciales.",
        "error"
      );
    };

    await fetchData(
      backendURL + "/account/login",
      "",
      init,
      setAccountData,
      notifyError
    )
  };

  const signout = () => {
    removeCookie("token");
    setIsSignedIn(false);
    return <Navigate to="/login" replace />;
  };

  useEffect(() => {
    const isAuthenticated = cookies.token ? true : false;
    setIsSignedIn(isAuthenticated);
  }, [cookies]);

  return (
    <Routes>
      <Route exact path="/login" element={<Login signin={signin} />} />
      <Route
        exact
        path="*"
        element={
          <Protected isSignedIn={isSignedIn}>
            <Dashboard signout={signout} />
          </Protected>
        }
      />
    </Routes>
  );
}
