import React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import { useCookies } from "react-cookie";
import { useSnackbar, closeSnackbar } from "notistack";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Title from "../components/Title";
import moment from "moment";
import LoadingComponent from "../components/LoadingComponent";
import SummaryCard from "../components/SummaryCard";
import PredictionPlot from "../components/PredictionPlot";
import Copyright from "../components/Copyright";
import { backendURL } from "../config/Constants";
import { fetchData, notify } from "../utils/Utils";

const ModelPage = ({ periodo, selectedDates }) => {
  const [cookies] = useCookies(["token"]);
  const [varList, setVarList] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [createdModels, setCreatedModels] = useState(null);
  const [selectedModelData, setSelectedModelData] = useState(null);
  const [selectedModelPredictions, setSelectedModelPredictions] =
    useState(null);
  const [selectedVar, setSelectedVar] = useState("");
  const [modelCreateResp, setModelCreateResp] = useState(null);
  const stateRef = useRef([]);
  const { enqueueSnackbar } = useSnackbar();

  const formatModelPredictionsData = (data) => {
    data.forEach((d) => {
      d.Fecha = moment(d.Fecha).valueOf(); // date -> epoch
    });
    setSelectedModelPredictions(data);
  };

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleClick = useCallback(
    async (id) => {
      const init = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + cookies.token,
          Accept: "application/json",
        },
      };
      const queryParams = new URLSearchParams({
        include_var_importance: true,
      });

      await fetchData(
        `${backendURL}/ai/modelo/${id}?`,
        queryParams,
        init,
        setSelectedModelData
      );

      await fetchData(
        `${backendURL}/ai/modelo/${id}/performancePredict`,
        "",
        init,
        formatModelPredictionsData
      );
    },
    [cookies]
  );

  const handleDelete = async (id) => {
    const init = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + cookies.token,
        Accept: "application/json",
      },
    };

    const setUpdateCreatedModels = (data) => {
      setCreatedModels(createdModels.filter((model) => model.id !== id));
      notify(enqueueSnackbar, "Modelo eliminado exitosamente", "success");
    };

    const notifyError = (error) => {
      notify(enqueueSnackbar, `Error al eliminar el modelo: ${error}`, "error");
    };

    await fetchData(
      `${backendURL}/ai/modelo/${id}`,
      "",
      init,
      setUpdateCreatedModels,
      notifyError
    );
  };

  const fetchModelData = useCallback(async () => {
    const init = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookies.token,
        Accept: "application/json",
      },
    };
    const queryParams = new URLSearchParams({
      only_keys: true,
    });

    const setFilterVarList = (data) => {
      setVarList(data.filter((varName) => varName !== "Calidad_Agua"));
    };

    await fetchData(
      `${backendURL}/parser/variables?`,
      queryParams,
      init,
      setFilterVarList
    );

    const setFilterCreatedModels = (data) => {
      stateRef.current.filter((pendingModel) => {
        const model = data.find(d => d.name === pendingModel.modelName);
        const modelLastModified = model ? moment.utc(model.lastModified) : null;
        if (model && modelLastModified.isAfter(pendingModel.notifyDate)) {
          notify(
            enqueueSnackbar,
            `Modelo ${pendingModel.modelName.replace(
              "modelo_",
              ""
            )} creado exitosamente`,
            "success"
          );
          closeSnackbar(pendingModel.notifyId);
          stateRef.current = stateRef.current.filter(
            (d) => d.modelName !== pendingModel.modelName
          );
          return true;
        }
        return false;
      })
      setCreatedModels(data);
    };

    await fetchData(`${backendURL}/ai/modelo`, "", init, setFilterCreatedModels);
  }, [cookies, enqueueSnackbar]);

  useEffect(() => {
    // check if createdModels is not null or empty
    if (createdModels && createdModels.length > 0 && firstLoad) {
      // call handleClick with the first element's id
      handleClick(createdModels[0].id);
    }
  }, [createdModels, handleClick, firstLoad]);

  useEffect(() => {
    if (modelCreateResp?.model_name && modelCreateResp?.task_id) {
      const modelTaskNotifyId = notify(
        enqueueSnackbar,
        `Modelo ${modelCreateResp.model_name.replace(
          "modelo_",
          ""
        )} enviado a crearse exitosamente`,
        "info",
        true,
      );
      stateRef.current.push({
        notifyId: modelTaskNotifyId,
        notifyDate: moment.utc(),
        modelName: modelCreateResp.model_name,
      });
    }
  }, [modelCreateResp, enqueueSnackbar]);

  useEffect(() => {
    // Create a fetch every 5 seconds
    fetchModelData();
    const interval = setInterval(() => {
      setFirstLoad(false);
      fetchModelData();
    }, 5000);
    return () => {
      clearInterval(interval);
      closeSnackbar();
    };
  }, [fetchModelData]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Model creator */}
        <Grid item xs={12} md={6} lg={6}>
          {!varList?.length ? (
            <LoadingComponent />
          ) : (
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 170,
              }}
            >
              <Title>Creador de modelos</Title>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="var-select-label">Variables</InputLabel>
                  <Select
                    labelId="var-select-label"
                    id="var-select"
                    value={selectedVar}
                    label="Tipo de variables"
                    onChange={(e) => {
                      setSelectedVar(e.target.value);
                    }}
                  >
                    {varList.map((varName) => (
                      <MenuItem key={varName} value={varName}>
                        {varName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  sx={{ mt: 1 }}
                  variant="contained"
                  onClick={() => {
                    const init = {
                      method: "POST",
                      headers: {
                        Authorization: "Bearer " + cookies.token,
                        Accept: "application/json",
                      },
                    };
                    const queryParams = new URLSearchParams({
                      objetivo: selectedVar,
                      periodo,
                    });
                    if (periodo === 3) {
                      queryParams.append(
                        "periodo_custom_start",
                        selectedDates[0].format("DD/MM/YYYY")
                      );
                      queryParams.append(
                        "periodo_custom_end",
                        selectedDates[1].format("DD/MM/YYYY")
                      );
                    }
                    fetchData(
                      `${backendURL}/ai/modelo?`,
                      queryParams,
                      init,
                      setModelCreateResp
                    );
                  }}
                >
                  Crear
                </Button>
                {/* <h1>Task ID: {modelCreateResp ? modelCreateResp.task_id : ""}</h1> */}
              </Box>
            </Paper>
          )}
        </Grid>
        {/* Created models */}
        {createdModels?.length > 0 && (
          <Grid item xs={12} md={6} lg={6}>
            {!createdModels ? (
              <LoadingComponent />
            ) : (
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  minHeight: 170,
                }}
              >
                <Title>Modelos creados</Title>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {createdModels.map((item) => (
                    <Grid item xs={2} sm={4} md={4} key={item.id}>
                      <Chip
                        label={item.name.replace("modelo_", "")}
                        title={item.name}
                        onClick={() => handleClick(item.id)}
                        onDelete={() => handleDelete(item.id)}
                        color="primary"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            )}
          </Grid>
        )}
        {/* Model performance */}
        {createdModels?.length > 0 && (
          <Grid item xs={12}>
            {!selectedModelData || !selectedModelPredictions ? (
              <LoadingComponent />
            ) : (
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  // maxHeight: 550,
                }}
              >
                <Title>Desempeño del modelo</Title>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab label="Resumen" />
                    <Tab label="Predicciones" />
                  </Tabs>
                </Box>
                {tabIndex === 0 && <SummaryCard data={selectedModelData} />}
                {tabIndex === 1 && (
                  <PredictionPlot
                    data={selectedModelPredictions}
                    title={selectedModelData?.targetVariable}
                  />
                )}
              </Paper>
            )}
          </Grid>
        )}
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default ModelPage;
