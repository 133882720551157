import React from "react";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import Input from "@mui/material/Input";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const FeatureSlider = ({ feature, setter, index }) => {
  const marks = [
    {
      value: feature.min,
      label: parseFloat(feature.min.toFixed(1)),
    },
    {
      value: feature.max,
      label: parseFloat(feature.max.toFixed(1)),
    },
  ];

  const handleSliderChange = (event, newValue) => {
    setter(feature.variable, newValue);
  };

  const handleInputChange = (event) => {
    setter(
      feature.variable,
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const _renderUnits = () => {
    if (feature.unit) return ` [${feature.unit}]`;
    return "";
  };

  return (
    <Grid container columnSpacing={4} alignItems="center">
      <Grid item xs={12}>
        <Tooltip title={feature.variable + _renderUnits()} placement="top-start" arrow>
          <Typography
            id={`input_slider_${feature.variable}`}
            gutterBottom
            noWrap
          >
            {index}. {feature.variable}{_renderUnits()}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={8} lg={9}>
        <Slider
          defaultValue={feature.mean}
          aria-labelledby={`input_slider_${feature.variable}`}
          value={
            typeof feature.value === "number" ? feature.value : feature.mean
          }
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          min={feature.min}
          max={feature.max}
          marks={marks}
          step={0.1}
        />
      </Grid>
      <Grid item xs={4} lg={3}>
        <Input
          value={feature.value}
          size="small"
          fullWidth
          onChange={handleInputChange}
          inputProps={{
            min: feature.min,
            max: feature.max,
            type: "number",
            step: 0.1,
            "aria-labelledby": `input_slider_${feature.variable}`,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FeatureSlider;
