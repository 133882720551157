import React from "react";
import { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { useSnackbar, closeSnackbar } from "notistack";
import moment from "moment";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import MediationIcon from "@mui/icons-material/Mediation";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ConstrainSlider from "../components/ConstrainSlider";
import { backendURL, periodRanges } from "../config/Constants";
import { fetchData, notify } from "../utils/Utils";

const Optimization = ({
  period,
  periodCustomEnd,
  createdModels,
  selectedModel,
  selectedModelFeatures,
  setSelectedModelFeatures,
  selectedModelUnit,
}) => {
  const [cookies] = useCookies(["token"]);
  const [
    selectedModelOptimizationResponse,
    setSelectedModelOptimizationResponse,
  ] = useState(null);
  const [selectedModelOptimization, setSelectedModelOptimization] =
    useState(null);
  const refNotifyId = useRef(null);
  const [objective, setObjective] = useState("min");
  const { enqueueSnackbar } = useSnackbar();
  var interval = null;

  const setter = (variable, newValue) => {
    setSelectedModelFeatures(
      selectedModelFeatures.map((item) => {
        if (item.variable === variable && !variable.includes("manipulable")) {
          return { ...item, value: newValue };
        } else if (
          item.variable === variable &&
          variable.includes("manipulable")
        ) {
          return { ...item, valueConstraint: newValue };
        }
        return item;
      })
    );
  };

  const handleRadioChange = (event) => {
    setObjective(event.target.value);
  };

  const fetchOptimize = async (modelID, features, fecha) => {
    const data = features.reduce(
      (acc, { variable, valueConstraint, value }) => {
        if (variable.includes("manipulable")) {
          acc["bounds_manipulated"][variable] = {
            min: valueConstraint[0],
            max: valueConstraint[1],
          };
        } else {
          acc["bounds_fixed"][variable] = value;
        }
        return acc;
      },
      { bounds_manipulated: {}, bounds_fixed: {} }
    );

    const init = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + cookies.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Fecha: fecha,
        ...data,
      }),
    };

    const queryParams = new URLSearchParams({
      objective,
    });

    await fetchData(
      `${backendURL}/ai/modelo/${modelID}/optimize?`,
      queryParams,
      init,
      setSelectedModelOptimizationResponse
    );
  };

  const fetchOptimizeResult = async (modelID, optimDate) => {
    const init = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookies.token,
        Accept: "application/json",
      },
    };

    const setResult = (data) => {
      if (data?.timestamp) {
        const timestamp = moment.utc(data.timestamp);
        if (timestamp.isAfter(optimDate)) {
          setSelectedModelOptimization(data);
          notify(enqueueSnackbar, "Optimización finalizada", "success");
          closeSnackbar(refNotifyId.current);
          clearInterval(interval);
        }
      }
    };

    const queryParams = new URLSearchParams({
      limit: 1,
    });

    await fetchData(
      `${backendURL}/ai/modelo/${modelID}/optimize?`,
      queryParams,
      init,
      setResult
    );
  };

  const fetchExportCSV = (modelID) => {
    const init = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookies.token,
        Accept: "text/csv",
      },
    };

    const queryParams = new URLSearchParams({
      csv_export: true,
    });

    fetch(
      `${backendURL}/ai/modelo/${modelID}/optimize?${queryParams}`,
      init
    ).then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `optimizaciones_modelo_${modelID}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
    });
  };

  useEffect(() => {
    if (
      selectedModelOptimizationResponse?.optimization_name &&
      selectedModelOptimizationResponse?.task_id
    ) {
      const sentNotifyId = notify(
        enqueueSnackbar,
        `Modelo ${selectedModelOptimizationResponse.optimization_name.replace(
          "optimizacion_modelo_",
          ""
        )} enviado a optimizarse`,
        "info",
        true
      );
      refNotifyId.current = sentNotifyId;
    }
  }, [selectedModelOptimizationResponse, enqueueSnackbar]);

  useEffect(() => {
    setSelectedModelOptimization(null);
    return () => closeSnackbar();
  }, [selectedModel]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <FormControl>
            <Typography variant="button" color="primary">
              Objetivo
            </Typography>
            <RadioGroup
              row
              // aria-labelledby="optim-type-label"
              name="row-radio-buttons-group"
              defaultValue="min"
              value={objective}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="min"
                control={<Radio />}
                label="Minimizar"
              />
              <FormControlLabel
                value="max"
                control={<Radio />}
                label="Maximizar"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="button" color="primary">
            Restricciones
          </Typography>
          <Grid container spacing={1} padding={1} columnSpacing={5}>
            {selectedModelFeatures?.map((feature, index) => (
              <Grid item xs={12} md={6} key={index}>
                <ConstrainSlider
                  feature={feature}
                  setter={setter}
                  index={index + 1}
                  result={
                    feature.variable.includes("manipulable")
                      ? selectedModelOptimization?.optimVars[feature.variable]
                      : selectedModelOptimization
                      ? feature.value
                      : null
                  }
                />
                <Divider />
              </Grid>
            ))}
          </Grid>
        </Grid>
        {selectedModelFeatures?.length > 0 && (
          <Grid
            // sx={{ backgroundColor: "black" }}
            container
            spacing={2}
            marginTop={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6} md={4}>
              <Button
                variant="contained"
                onClick={() => {
                  fetchOptimize(
                    createdModels.find((model) => model.name === selectedModel)
                      .id,
                    selectedModelFeatures,
                    period === 3
                      ? moment(periodCustomEnd).format("DD/MM/YYYY")
                      : moment(periodRanges[period][1]).format("DD/MM/YYYY")
                  ).then(() => {
                    const optimDate = moment.utc();
                    interval = setInterval(() => {
                      fetchOptimizeResult(
                        createdModels.find(
                          (model) => model.name === selectedModel
                        ).id,
                        optimDate
                      );
                    }, 5000);
                  });
                }}
              >
                Optimizar
              </Button>
            </Grid>
            {selectedModelOptimization && (
              <Grid item xs={6} md={4}>
                <List sx={{ maxHeight: "md" }}>
                  <ListItem>
                    <ListItemAvatar>
                      <MediationIcon />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Solución Encontrada"
                      secondary={
                        parseFloat(selectedModelOptimization.fun.toFixed(4)) +
                        (selectedModelUnit ? ` [${selectedModelUnit}]` : "")
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={4}
              container
              justifyContent={{ xs: "flex-start", md: "flex-end" }}
            >
              <Button
                variant="outlined"
                color="success"
                startIcon={<SaveAltIcon />}
                onClick={() => {
                  fetchExportCSV(
                    createdModels.find((model) => model.name === selectedModel)
                      .id
                  );
                }}
              >
                Descargar optimizaciones
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Optimization;
