import React from "react";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import Input from "@mui/material/Input";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const ConstrainSlider = ({ feature, setter, index, result }) => {
  const marks = [
    {
      value: feature.min,
      label: parseFloat(feature.min.toFixed(1)),
    },
    {
      value: feature.max,
      label: parseFloat(feature.max.toFixed(1)),
    },
  ];

  const handleSliderChange = (event, newValue) => {
    setter(feature.variable, newValue);
  };

  const handleInputChange = (event, limit) => {
    if (limit === "min") {
      setter(
        feature.variable,
        event.target.value === ""
          ? ""
          : [Number(event.target.value), feature.valueConstraint[1]]
      );
    } else if (limit === "max") {
      setter(
        feature.variable,
        event.target.value === ""
          ? ""
          : [feature.valueConstraint[0], Number(event.target.value)]
      );
    } else {
      setter(
        feature.variable,
        event.target.value === "" ? "" : Number(event.target.value)
      );
    }
  };

  const _renderSlider = () => {
    if (feature.variable.includes("manipulable")) {
      return (
        <>
          <Grid item xs={3} lg={2}>
            <Input
              value={feature.valueConstraint[0]}
              size="small"
              fullWidth
              onChange={(event) => handleInputChange(event, "min")}
              inputProps={{
                min: feature.min,
                max: feature.max,
                type: "number",
                step: 0.1,
                "aria-labelledby": `input_slider_${feature.variable}`,
              }}
            />
          </Grid>
          <Grid item xs={6} lg={8}>
            <Slider
              defaultValue={[feature.min, feature.max]}
              aria-labelledby={`input_slider_${feature.variable}`}
              value={
                typeof feature.valueConstraint[0] === "number" &&
                typeof feature.valueConstraint[1] === "number"
                  ? feature.valueConstraint
                  : [feature.min, feature.max]
              }
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              min={feature.min - feature.min * 0.2}
              max={feature.max + feature.max * 0.2}
              marks={marks}
              step={0.1}
            />
          </Grid>
          <Grid item xs={3} lg={2}>
            <Input
              value={feature.valueConstraint[1]}
              size="small"
              fullWidth
              onChange={(event) => handleInputChange(event, "max")}
              inputProps={{
                min: feature.min,
                max: feature.max,
                type: "number",
                step: 0.1,
                "aria-labelledby": `input_slider_${feature.variable}`,
              }}
            />
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid item xs={8} lg={9}>
          <Slider
            defaultValue={feature.mean}
            aria-labelledby={`input_slider_${feature.variable}`}
            value={
              typeof feature.value === "number" ? feature.value : feature.mean
            }
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            min={feature.min}
            max={feature.max}
            marks={marks}
            step={0.1}
          />
        </Grid>
        <Grid item xs={4} lg={3}>
          <Input
            value={feature.value}
            size="small"
            fullWidth
            onChange={(event) => handleInputChange(event, null)}
            inputProps={{
              min: feature.min,
              max: feature.max,
              type: "number",
              step: 0.1,
              "aria-labelledby": `input_slider_${feature.variable}`,
            }}
          />
        </Grid>
      </>
    );
  };

  const _renderUnits = () => {
    if (feature.unit) return ` [${feature.unit}]`;
    return "";
  };

  return (
    <Grid container columnSpacing={4} alignItems="center">
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Tooltip title={feature.variable + _renderUnits()} placement="top-start" arrow>
            <Typography
              id={`input_slider_${feature.variable}`}
              gutterBottom
              noWrap
            >
              {index}. {feature.variable}{_renderUnits()}
            </Typography>
          </Tooltip>
          {result && (
            <Tooltip title={result.toFixed(2)} placement="top-end" arrow>
              <Typography gutterBottom noWrap color="primary">
                {parseFloat(result.toFixed(2))}
              </Typography>
            </Tooltip>
          )}
        </Stack>
      </Grid>
      {_renderSlider()}
    </Grid>
  );
};

export default ConstrainSlider;
