export const backendURL = process.env.REACT_APP_API_URL || "/api/v1";

export const profileVarsUnits = {
  efluente_DBO5t_conc: "mgO2/L",
  efluente_DQOt_conc: "mgO2/L",
  efluente_MES_conc: "mg/L",
  efluente_Ntk_conc: "mg/L",
  efluente_Pt_conc: "mg/L",
};

export const periodMap = {
  "Período 1": 1,
  "Período 2": 2,
  Personalizado: 3,
};

export const periodRanges = {
  1: ["2013-01-01", "2014-12-31"],
  2: ["2018-05-01", "2021-07-26"],
};

export const timeFeatures = [
  "month",
  "day",
  "dayofweek",
  "dayofyear",
  "weekofyear",
  "quarter",
];
