import React from "react";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingComponent = () => {
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        height: 500,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Paper>
  );
};

export default LoadingComponent;
