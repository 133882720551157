import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const NotFound = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            404 - Página no encontrada
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound;
