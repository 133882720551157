export const fetchData = async (
  url,
  queryParams,
  init,
  setData,
  setError = null
) => {
  try {
    const response = await fetch(url + queryParams, init);
    const data = await response.json();
    if (data.error) {
      throw new Error(data.error);
    }
    setData(data);
  } catch (error) {
    console.log(error);
    if (setError) setError(error);
    if (error.message === "unauthorized_token") {
      document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      window.location.replace("/login");
    }
  }
};

export const orderProfileData = (data) => {
  return Object.entries(data)
    .map(([variable, importances]) => {
      return { variable, importance: importances.importance };
    })
    .sort((a, b) => b.importance - a.importance);
};

export const orderPerformanceData = (data) => {
  return Object.entries(data)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 10)
    .map(([variable, importance]) => ({ variable, importance }));
};

export const notify = (notifier, message, variant, persist=false) => {
  const notifyId = notifier(message, {
    variant,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    persist,
  });
  return notifyId;
};
