import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import 'moment/locale/es';

const locale = 'es-ES';

export default function BasicDateRangePicker({
  handleOpen,
  handleClose,
  handleChangeStart,
  handleChangeEnd,
  periodCustomStart,
  periodCustomEnd,
  minDate,
  maxDate,
}) {
  return (
    <Dialog
      disableEscapeKeyDown
      open={handleOpen}
      onClose={() => handleClose(false)}
    >
      <DialogTitle>Selecionar período</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
          <Box component={"form"} sx={{ display: "flex", flexWrap: "wrap" }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <DatePicker
                label="Inicio"
                minDate={minDate}
                maxDate={maxDate}
                value={periodCustomStart}
                onChange={(newValue) => {
                  handleChangeStart(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <DatePicker
                label="Fin"
                minDate={minDate}
                maxDate={maxDate}
                value={periodCustomEnd}
                onChange={(newValue) => {
                  handleChangeEnd(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Box>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancelar</Button>
        <Button
          onClick={() => handleClose(true)}
          disabled={
            periodCustomStart.isBefore(minDate) ||
            periodCustomEnd.isBefore(minDate) ||
            periodCustomStart.isAfter(periodCustomEnd)
          }
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
