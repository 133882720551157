import React from "react";
import { Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  LabelList,
} from "recharts";
import Title from "./Title";

export default function FeatureImportance({ data, title }) {
  const theme = useTheme();

  return (
    <Fragment>
      <Title>{title}</Title>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 0,
            bottom: 30,
            left: 0,
          }}
          layout="vertical"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis type="category" dataKey="variable" width={200} hide />
          <Tooltip />
          <Bar
            dataKey="importance"
            fill={theme.palette.primary.main}
            isAnimationActive={false}
          >
            <LabelList
              dataKey="variable"
              position="insideLeft"
              fill={theme.palette.text.primary}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Fragment>
  );
}
