import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Title from "../components/Title";
import LoadingComponent from "../components/LoadingComponent";
import Simulation from "../components/Simulation";
import { backendURL, timeFeatures } from "../config/Constants";
import { fetchData, orderPerformanceData, notify } from "../utils/Utils";
import Optimization from "../components/Optimization";

const SimulOptimPage = ({ periodo, selectedDates }) => {
  const [cookies] = useCookies(["token"]);
  const [loading, setLoading] = useState(true);
  const [createdModels, setCreatedModels] = useState(null);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedModelData, setSelectedModelData] = useState(null);
  const [selectedModelFeatures, setSelectedModelFeatures] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const fetchCreatedModels = useCallback(
    async (
      include_var_units = false,
      include_var_means = false,
      include_var_importance = false,
      include_var_limits = false,
      modelID = null,
      setter
    ) => {
      const init = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + cookies.token,
          Accept: "application/json",
        },
      };
      const queryParams = new URLSearchParams({
        include_var_units,
        include_var_means,
        include_var_importance,
        include_var_limits,
      });

      if (modelID) {
        await fetchData(
          `${backendURL}/ai/modelo/${modelID}?`,
          queryParams,
          init,
          setter
        );
      } else {
        await fetchData(`${backendURL}/ai/modelo?`, queryParams, init, setter);
      }
    },
    [cookies]
  );

  useEffect(() => {
    if (!selectedModel) return;
    // Remove time features from selectedModelData.variableImportance Object
    const varFeaturesNoTime = Object.keys(
      selectedModelData.variableImportance
    ).reduce((acc, key) => {
      if (!timeFeatures.includes(key)) {
        acc[key] = selectedModelData.variableImportance[key];
      }
      return acc;
    }, {});

    const data = orderPerformanceData(varFeaturesNoTime);
    // Extract only the variable names into a list
    const features = data.map((d) => ({
      variable: d.variable,
      unit: selectedModelData.variableUnits[d.variable],
      mean: selectedModelData.variableMeans[d.variable],
      min: selectedModelData.variableLimits[d.variable].min,
      max: selectedModelData.variableLimits[d.variable].max,
      value: selectedModelData.variableMeans[d.variable],
      valueConstraint: [
        selectedModelData.variableLimits[d.variable].min,
        selectedModelData.variableLimits[d.variable].max,
      ],
    }));

    setSelectedModelFeatures(features);
  }, [selectedModel, selectedModelData]);

  useEffect(() => {
    if (!createdModels || createdModels?.length === 0) return;
    fetchCreatedModels(
      true,
      true,
      true,
      true,
      createdModels[0].id,
      setSelectedModelData
    );
  }, [fetchCreatedModels, createdModels]);

  useEffect(() => {
    fetchCreatedModels(false, false, false, false, null, setCreatedModels)
      .then(() => setLoading(false))
      .catch((error) => {
        console.log(error);
        notify(enqueueSnackbar, "Error al cargar los modelos creados", "error");
        setLoading(false);
      });
  }, [fetchCreatedModels, enqueueSnackbar]);

  const _renderContent = () => {
    if (loading) return <LoadingComponent />;

    if (!createdModels?.length) {
      return (
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title>No existen modelos</Title>
        </Paper>
      );
    }

    return (
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Title>Simulador y optimizador de modelos</Title>
        <Box sx={{ minWidth: 120 }} marginTop={1}>
          <FormControl fullWidth>
            <InputLabel id="var-select-label">Modelos</InputLabel>
            <Select
              labelId="var-select-label"
              id="var-select"
              value={selectedModel}
              label="Modelos"
              onChange={(e) => {
                fetchCreatedModels(
                  true,
                  true,
                  true,
                  true,
                  createdModels.find((model) => model.name === e.target.value)
                    .id,
                  setSelectedModelData
                );
                setSelectedModel(e.target.value);
              }}
            >
              {createdModels.map((model) => (
                <MenuItem key={model.id} value={model.name}>
                  {model.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedModel && (
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="Simulación" />
                <Tab label="Optimización" />
              </Tabs>
            </Box>
          )}
          {selectedModel && tabIndex === 0 && (
            <Simulation
              period={selectedModelData.period}
              periodCustomEnd={selectedModelData.periodCustomEnd}
              createdModels={createdModels}
              selectedModel={selectedModel}
              selectedModelFeatures={selectedModelFeatures}
              setSelectedModelFeatures={setSelectedModelFeatures}
              selectedModelUnit={
                selectedModelData?.variableUnits[
                  selectedModelData.targetVariable
                ]
              }
            />
          )}
          {selectedModel && tabIndex === 1 && (
            <Optimization
              period={selectedModelData.period}
              periodCustomEnd={selectedModelData.periodCustomEnd}
              createdModels={createdModels}
              selectedModel={selectedModel}
              selectedModelFeatures={selectedModelFeatures}
              setSelectedModelFeatures={setSelectedModelFeatures}
              selectedModelUnit={
                selectedModelData?.variableUnits[
                  selectedModelData.targetVariable
                ]
              }
            />
          )}
        </Box>
      </Paper>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Model creator */}
        <Grid item xs={12}>
          {_renderContent()}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SimulOptimPage;
